import {
    FacebookLoginProvider,
    GoogleLoginProvider,
    SocialAuthServiceConfig,
    SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { CommonModule } from '@angular/common';
import {
    HttpClient,
    HttpClientModule,
    HttpClientXsrfModule,
    HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './guards/auth.guard';
import { GuestGuard } from './guards/guest.guard';
import { AuthService } from './services/auth.service';
import { LandingComponent } from './pages/landing/landing.component';
import { SiteLayoutComponent } from './layouts/site-layout/site-layout.component';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { BearerInterceptor } from './interceptors/bearer.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { LangSwitcherService } from './services/lang-switcher.service';
import { LocaleInterceptor } from './interceptors/locale.interceptor';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { EmptyLayoutComponent } from './layouts/empty-layout/empty-layout.component';
import { Router, RouterModule } from '@angular/router';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { AgmCoreModule } from '@agm/core';
import { SharedDirectiveModule } from './shared-directive/shared-directive.module';
import { FocusedLayoutComponent } from './layouts/focused-layout/focused-layout.component';
// import * as Sentry from "@sentry/angular" // for Angular 10/11 instead
import * as Sentry from '@sentry/angular-ivy';
import { environment } from 'src/environments/environment';
import { OneTimeTokenInterceptor } from './interceptors/one-time-token.interceptor';
import { MobileDeepLinkInterceptor } from './interceptors/mobile-deep-link.interceptor';
import { CustomLoader } from './services/custom-loader';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
    return player;
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return environment.useLocalTranslations
        ? new TranslateHttpLoader(http)
        : new CustomLoader(http);
}

const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: environment.baseUrl.replace('https://', ''), // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
    },
    palette: {
        popup: {
            background: '#ffa726',
            text: "#ffffff",
            link: "#ffffff"
        },
        button: {
            background: '#ffffff',
            text: "#000000",
            border: "#transparent"
        },
    },
    theme: 'classic',
    type: 'opt-out',
    position: "bottom-right",
};

@NgModule({
    declarations: [
        AppComponent,
        LandingComponent,
        SiteLayoutComponent,
        AppLayoutComponent,
        PageNotFoundComponent,
        TermsAndConditionsComponent,
        EmptyLayoutComponent,
        FocusedLayoutComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CommonModule,
        SocialLoginModule,
        HttpClientModule,
        NgbModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        TranslateModule.forRoot({
            defaultLanguage:
                LangSwitcherService.getUserSelectedLang() ||
                LangSwitcherService.defaultLang,
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        SharedModule,
        RouterModule,
        LottieModule.forRoot({ player: playerFactory }),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyCSz3NWRTNxYaR1KQJquKh8kxtXN4huOig',
        }),
        HttpClientXsrfModule,
        SharedDirectiveModule,
        NgcCookieConsentModule.forRoot(cookieConfig),
    ],
    providers: [
        AuthService,
        GuestGuard,
        AuthGuard,
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            '715740436428-6lnp1imkosepndjhosoj6pajd985m4jr.apps.googleusercontent.com'
                        ),
                    },
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider('3485540564861121'),
                    },
                ],
                onError: (err: any) => {
                    console.error(err);
                },
            } as SocialAuthServiceConfig,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BearerInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LocaleInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OneTimeTokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MobileDeepLinkInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: !environment.production,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
