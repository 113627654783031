import { Country } from "./country";
import { Permission } from "./permission";
import { Resource } from "./resource"
import { Role } from "./role";
import { UserConfig } from "./user-config";
import { UserSetting } from "./user-setting";

export class User extends Resource {

    private readonly configKeys: string[] = [
        'MAX_NUMBER_OF_VEHICLES'
    ];

    override id: number;
    avatar: string;
    email: string;
    firstName: string;
    lastName: string;
    roles: Role[];
    permissions: Permission[];
    settings: UserSetting[];
    config: UserConfig[];
    country: Country | null;
    emailVerified: boolean;
    coinBalance: string;
    coinUsage: number;
    authType: 'social' | 'standard';

    constructor(
        id: number,
        avatar: string,
        email: string,
        firstName: string,
        lastName: string,
        roles: Role[],
        permissions: Permission[],
        settings: UserSetting[],
        config: UserConfig[],
        country: Country | null,
        emailVerified: boolean,
        coinBalance: string,
        coinUsage: number,
        authType: 'social' | 'standard'
    ) {
        super();
        this.id = id;
        this.avatar = avatar;
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
        this.roles = roles;
        this.permissions = permissions;
        this.settings = settings;
        this.config = config;
        this.country = country;
        this.emailVerified = emailVerified;
        this.coinBalance = coinBalance;
        this.coinUsage = coinUsage;
        this.authType = authType;
    }

    getSettingFor(settingSlug: string, model = false): UserSetting | string | null {
        const item = this.settings.find(x => x.slug === settingSlug);
        if (!item) {
            return null;
        }
        return model ? item : item.value;
    }

    hasRole(role: string): boolean {
        return this.roles.find(x => x.name === role) !== undefined;
    }

    private getConfigValue(key: string): any | undefined {
        return this.config.find(x => x.key === key)?.value || undefined;
    }

    getMaxNrOfVehicles(): number | undefined {
        const value = this.getConfigValue(this.configKeys[0]);
        return value ? Number(value) : undefined;
    }

}