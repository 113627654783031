import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { RegistrationRequest } from 'src/app/models/resource-models/registration-request';
import { RegistrationRequestSerializer } from 'src/app/models/serializers/registration-request-serializer';
import { Create } from '../http-methods/create';
import { AuthService } from '../auth.service';
import { User } from 'src/app/models/resource-models/user';
import { UserSerializer } from 'src/app/models/serializers/user-serializer';
import { UserUpdateRequest } from 'src/app/models/resource-models/user-update-request';
import { UserUpdateRequestSerializer } from 'src/app/models/serializers/user-update-request-serializer';
import { Patch } from '../http-methods/patch';
import { Read } from '../http-methods/read';
import { EmailResendRequest } from 'src/app/models/resource-models/email-resend-request';
import { EmailResendRequestSerializer } from 'src/app/models/serializers/email-resend-request-serializer';
import { GenericResponse } from 'src/app/models/resource-models/generic-response';
import { GenericResponseSerializer } from 'src/app/models/serializers/generic-response-serializer';
import { UserSetting } from 'src/app/models/resource-models/user-setting';
import { LangSwitcherService } from '../lang-switcher.service';
import { TranslateService } from '@ngx-translate/core';
import { StorageHelper } from 'src/app/helpers/storage-helper';
import { Delete } from '../http-methods/delete';
import { Empty } from 'src/app/models/resource-models/empty';
import { EmptySerializer } from 'src/app/models/serializers/empty-serializer';
import { OneTimeToken } from 'src/app/models/resource-models/one-time-token';
import { OneTimeTokenSerializer } from 'src/app/models/serializers/one-time-token-serializer';
import { BearerToken } from 'src/app/models/resource-models/bearer-token';
import { BearerTokenSerializer } from 'src/app/models/serializers/bearer-token-serializer';
import { UserDeleteRequest } from 'src/app/models/resource-models/user-delete-request';
import { UserDeleteRequestSerializer } from 'src/app/models/serializers/user-delete-request-serializer';
import { ReportBugRequestSerializer } from 'src/app/models/serializers/report-bug-request-serializer';
import { ReportBugRequest } from 'src/app/models/resource-models/report-bug-request';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private storageWebUserSubject = new BehaviorSubject<User | null>(null);
    storageWebUser$ = this.storageWebUserSubject.asObservable();

    public signupEndpoint = environment.userVersion + '/signup';
    public reportBugEndpoint = environment.userVersion;
    public userEndpoint = environment.userVersion + '/user';
    public verifyResendEndpoint = environment.userVersion + '/email/verify/resend';
    public abortedOnboardingEndpoint = environment.userVersion + '/aborted-onboarding';
    public oneTimeTokenEndpoint = environment.userVersion + '/one-time-token';

    private createMethod: Create<RegistrationRequest, User>;
    private patchUserMethod: Patch<UserUpdateRequest, User>;
    private readUserMethood: Read<User>;
    private resendEmailMethod: Create<EmailResendRequest, GenericResponse>;
    private readAbortedOnboardingMethod: Read<User>;
    private deleteAbortedOnboardingMethod: Delete<Empty>;
    private deleteUserMethod: Create<UserDeleteRequest, Empty>;
    private createOneTimeTokenMethod: Create<Empty, OneTimeToken>;
    private readOneTimeTokenMethod: Read<BearerToken>;
    private reportBugMethod: Create<FormData, Empty>;

    constructor(
        protected httpClient: HttpClient,
        protected auth: AuthService,
        private translate: TranslateService
    ) {
        this.reportBugMethod = new Create<FormData, Empty>(
            httpClient,
            auth,
            new EmptySerializer(),
            new EmptySerializer(),
            null,
            this.reportBugEndpoint + '/bugs/report'
        );
        this.createMethod = new Create<RegistrationRequest, User>(
            httpClient,
            auth,
            new RegistrationRequestSerializer(),
            new UserSerializer(),
            null,
            this.signupEndpoint
        );
        this.patchUserMethod = new Patch<UserUpdateRequest, User>(
            httpClient,
            auth,
            new UserUpdateRequestSerializer(),
            new UserSerializer(),
            null,
            this.userEndpoint
        );
        this.readUserMethood = new Read<User>(
            httpClient,
            auth,
            new UserSerializer(),
            null,
            this.userEndpoint
        );
        this.resendEmailMethod = new Create<EmailResendRequest, GenericResponse>(
            httpClient,
            auth,
            new EmailResendRequestSerializer(),
            new GenericResponseSerializer(),
            null,
            this.verifyResendEndpoint
        );
        this.readAbortedOnboardingMethod = new Read<User>(
            httpClient,
            auth,
            new UserSerializer(),
            null,
            this.abortedOnboardingEndpoint
        );
        this.deleteAbortedOnboardingMethod = new Delete<Empty>(
            httpClient,
            auth,
            new EmptySerializer(),
            null,
            this.abortedOnboardingEndpoint
        );
        this.deleteUserMethod = new Create<UserDeleteRequest, Empty>(
            httpClient,
            auth,
            new UserDeleteRequestSerializer(),
            new EmptySerializer(),
            null,
            this.userEndpoint + '/delete'
        );
        this.createOneTimeTokenMethod = new Create<Empty, OneTimeToken>(
            httpClient,
            auth,
            new EmptySerializer(),
            new OneTimeTokenSerializer(),
            null,
            this.oneTimeTokenEndpoint
        );
        this.readOneTimeTokenMethod = new Read<BearerToken>(
            httpClient,
            auth,
            new BearerTokenSerializer(),
            null,
            this.oneTimeTokenEndpoint
        );
    }

    public register(model: RegistrationRequest): Observable<User> {
        return this.createMethod.create(null, null, model);
    }

    public setOneTimeToken(): Observable<OneTimeToken> {
        return this.createOneTimeTokenMethod.create(null, null, new Empty());
    }

    public getOneTimeToken(token: string): Observable<BearerToken> {
        return this.readOneTimeTokenMethod.read(null, null, { token: token }, null);
    }

    public patch(model: UserUpdateRequest): Observable<User> {
        return this.patchUserMethod.patch(null, null, model);
    }

    public getUser(): Observable<User> {
        const data: string | null = StorageHelper.getItem('web_user');
        if (!data) {
            return this.readUserMethood.read(null, null, null, null);
        }
        return new Observable(observer => {
            const item = JSON.parse(data);
            observer.next(item);
            this.storageWebUserSubject.next(item);
            observer.complete();
        });
    }

    public setUser(user: User): void {
        this.storageWebUserSubject.next(user);
        StorageHelper.setItem('web_user', JSON.stringify(user), 10);
    }

    public clearUser(): void {
        this.storageWebUserSubject.next(null);
        StorageHelper.removeItem('web_user');
    }

    public verify(url: string): Observable<{ message: string, token: number }> {
        return this.httpClient.get<{ message: string, token: number }>(url);
    }

    public resendEmail(resendRequest: EmailResendRequest): Observable<GenericResponse> {
        this.resendEmailMethod.listKey = null;
        return this.resendEmailMethod.create(null, null, resendRequest);
    }

    public getAbortedOnboardingUser(deviceId: string): Observable<User> {
        return this.readAbortedOnboardingMethod.read(null, deviceId, null, null);
    }

    public reportBug(data: FormData): Observable<Empty> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        return this.reportBugMethod.createWithOptions(null, null, data, headers);
    }

    public deleteAbortedOnboardingUser(deviceId: string, userId: number): Observable<Empty> {
        return this.deleteAbortedOnboardingMethod.delete(null, deviceId + '/' + userId, null);
    }

    public deleteUser(model: UserDeleteRequest): Observable<Empty> {
        return this.deleteUserMethod.create(null, null, model);
    }

    getUserInstance(userInstance: User | null = null): User | null {
        const webUser = StorageHelper.getItem('web_user');
        let user: User | undefined | null = userInstance ? userInstance : webUser ? JSON.parse(StorageHelper.getItem('web_user') || '') as User : null;
        if (!user) {
            return null;
        }
        return new User(user.id, user.avatar, user.email, user.firstName, user.lastName, user.roles, user.permissions, user.settings, user.config, user.country, user.emailVerified, user.coinBalance, user.coinUsage, user.authType);
    }

    getUserSettingFor(settingSlug: string, model = false): UserSetting | string | null {
        let userInstance = this.getUserInstance();
        if (!userInstance) {
            return null;
        }
        return userInstance.getSettingFor(settingSlug, model);
    }

    setUserPreferredLanguage(): void {
        const userLocale = this.getUserSettingFor('user_locale') as string;
        if (!userLocale || userLocale === LangSwitcherService.getUserSelectedLang()) {
            return;
        }
        this.setLocale(userLocale);
    }

    setLocale(locale: string): void {
        if (!LangSwitcherService.isLangSupported(locale)) {
            return;
        }
        this.translate.use(locale);
        this.translate.setDefaultLang(locale);
        LangSwitcherService.setUserSelectedLang(locale);
    }

    hasRole(role: string): boolean {
        let userInstance = this.getUserInstance();
        if (!role || !userInstance) {
            return false;
        }
        return userInstance.hasRole(role);
    }

}
